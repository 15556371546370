import axios from "axios";
import notyfWrapper from "./notyfUtils";

axios.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

export const postWrapper = (
  url,
  data = {},
  successCallback = () => {},
  errorCallback = () => {},
) => {
  const notyf = notyfWrapper.getInstance();
  axios
    .post(url, data)
    .then(({ data: response }) => {
      if (response.error === false) {
        successCallback(response.data);
      } else {
        notyf.error(response.message);
        errorCallback(response.data);
      }
    })
    .catch((error) => {
      const { response } = error;
      if (response && response.data) {
        const { message = "Something went wrong!" } = response.data;
        notyf.error(message);
        errorCallback(response);
      } else {
        notyf.error("Something went wrong!");
        errorCallback();
      }
    });
};

export async function postAsync(url, data = {}) {
  const notyf = notyfWrapper.getInstance();
  try {
    let response = await axios.post(url, data);
    response = response.data;
    if (response.error === false) {
      return response.data;
    }
    notyf.error(response.message);
    return [];
  } catch (error) {
    const { response } = error;
    if (response && response.data) {
      const { message = "Something went wrong!" } = response.data;
      notyf.error(message);
    } else {
      notyf.error("Something went wrong!");
    }
    return [];
  }
}
