import React from "react";
import { HashLink } from "react-router-hash-link";

function Banner() {
  return (
    <section id="banner" className="major">
      <div className="inner">
        <header className="major">
          <h1>The Current Archives and Collections Index</h1>
        </header>
        <div className="content">
          <p>
            Connecting you to
            <br />
            black literary collections.
          </p>
          <ul
            className="actions"
            style={{
              maxHeight: "308px",
              overflow: "auto",
            }}
          >
            <li>
              <HashLink to="#searchtop" className="button next scrolly">
                Explore the CACI
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Banner;
