/* eslint-disable react/no-array-index-key */
import React from "react";
import Comment from "../Comment/Comment";
import { renderCategoryList } from "../../assets/js/utils";

const renderRegionInfo = ({ directory: regionsData, trending }) => {
  if (!regionsData) return "";
  return (
    <div className="row" id="caci-location-div">
      <div className="col-6 col-12-small">
        <div
          style={{
            border: "1px solid",
            padding: "5px",
            marginTop: "5px",
            overflowAnchor: "none",
            maxHeight: "308px",
            overflow: "auto",
          }}
        >
          <h3>Region Directory</h3>
          <ul className="tree" id="location-directory">
            {regionsData.map((region, regionIndex) => (
              <li key={`${region.label}-${regionIndex}`}>
                <input
                  className="tree-toggle"
                  type="checkbox"
                  id={`region-toggle-${regionIndex}`}
                />
                <label
                  className="tree-toggle-label"
                  htmlFor={`region-toggle-${regionIndex}`}
                >
                  <a
                    href={`caci-results?category=location&value=${encodeURIComponent(
                      region.label,
                    )}&from=&to=&sortBy=&orderBy=asc`}
                  >
                    {region.label}
                  </a>
                </label>
                <ul className="subtree">
                  {region["tier-2"] &&
                    region["tier-2"].map((tier2, tier2Index) => (
                      <li key={`${tier2.label}-${tier2Index}`}>
                        <input
                          className="tree-toggle"
                          type="checkbox"
                          id={`${region.value}-tier2-toggle-${tier2Index}`}
                        />
                        <label
                          className="tree-toggle-label"
                          htmlFor={`${region.value}-tier2-toggle-${tier2Index}`}
                        >
                          <a
                            href={`caci-results?category=location&value=${encodeURIComponent(
                              tier2.label,
                            )}&from=&to=&sortBy=&orderBy=asc`}
                          >
                            {tier2.label}
                          </a>
                        </label>
                        <ul className="subtree t3-list">
                          {tier2["tier-3"] &&
                            tier2["tier-3"].map((tier3, tier3Index) => (
                              <li key={`${tier3.label}-${tier3Index}`}>
                                <a
                                  href={`caci-results?category=location&value=${encodeURIComponent(
                                    tier3.label,
                                  )}&from=&to=&sortBy=&orderBy=asc`}
                                >
                                  {tier3.label}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="col-6 col-12-small">
        <div style={{ border: "1px solid", padding: "5px", marginTop: "5px" }}>
          <h3>Trending Regions</h3>
          <ol id="trending-locations">{renderCategoryList(trending)}</ol>
        </div>
        <div style={{ border: "1px solid", padding: "5px", marginTop: "5px" }}>
          <Comment
            type="region"
            heading="Don't see the region you're looking for? Tell us about it!"
          />
        </div>
      </div>
    </div>
  );
};

export default renderRegionInfo;
