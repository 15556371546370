/* eslint-disable react/jsx-no-bind */
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home/Home";
import Collections from "./components/Collections/Collections";
import Collection from "./components/Collection/Collection";
import AdminHome from "./components/AdminHome/AdminHome";
import InsertForm from "./components/InsertForm/InsertForm";
import ApprovalForm from "./components/ApprovalForm/ApprovalForm";
import UpdateForm from "./components/UpdateForm/UpdateForm";
import Login from "./components/Login/Login";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const parseJwt = (data = {}) => {
  if (!data || !data.token) return null;
  const { token } = data;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );
  return JSON.parse(jsonPayload);
};
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: parseJwt(JSON.parse(localStorage.getItem("profile"))),
    };
  }

  userUpdateHandler() {
    const user = parseJwt(JSON.parse(localStorage.getItem("profile")));
    this.setState({ user });
  }

  render() {
    const {
      state: { user },
    } = this;
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <ErrorBoundary info="while loading Home">
                <Home logoutCallback={this.userUpdateHandler.bind(this)} />
              </ErrorBoundary>
            }
          />
          <Route
            path="/caci/insert-collection"
            exact
            element={
              <ErrorBoundary info="in Insert Form">
                <InsertForm
                  logoutCallback={this.userUpdateHandler.bind(this)}
                />
              </ErrorBoundary>
            }
          />
          <Route
            path="/caci-results"
            exact
            element={
              <ErrorBoundary info="while loading Collections">
                <Collections
                  logoutCallback={this.userUpdateHandler.bind(this)}
                />
              </ErrorBoundary>
            }
          />
          <Route
            path="/caci-collection"
            exact
            element={
              <ErrorBoundary info="while loading the collection">
                <Collection
                  logoutCallback={this.userUpdateHandler.bind(this)}
                />
              </ErrorBoundary>
            }
          />
          {!user ? (
            <>
              <Route
                path="/login"
                exact
                element={
                  <ErrorBoundary info="while loading the login screen">
                    <Login
                      successCallback={this.userUpdateHandler.bind(this)}
                    />
                  </ErrorBoundary>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route
                path="/caci/admin-list"
                exact
                element={
                  <ErrorBoundary info="while loading the admin list">
                    <AdminHome
                      username={user.firstName}
                      logoutCallback={this.userUpdateHandler.bind(this)}
                    />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/caci/approve-collection"
                exact
                element={
                  <ErrorBoundary info="in collection approval form">
                    <ApprovalForm
                      logoutCallback={this.userUpdateHandler.bind(this)}
                    />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/caci/update-collection"
                exact
                element={
                  <ErrorBoundary info="in collection updation form">
                    <UpdateForm
                      logoutCallback={this.userUpdateHandler.bind(this)}
                    />
                  </ErrorBoundary>
                }
              />
              <Route path="*" element={<Navigate to="/caci/admin-list" />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
