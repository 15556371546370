/* eslint-disable react/no-unused-state */
import React from "react";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import pic7 from "../../images/pic07.jpg";
import Form from "../Form/Form";

class InsertForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "pending",
      collectionTitle: "",
      collectionUrl: "",
      collectionFormat: [],
      accessKeys: [],
      collectionPermission: "",
      collectionDescription: "",
      collectionContributor: [
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
      ],
      timePeriod: { from: "1700", to: "1800" },
      topics: [],
      topicRecommendation: "",
      collectionPhotos: [],
      collectionRepository: "",
      repositoryInstitution: "",
      collectionRepositoryUrl: "",
      location: {
        city: "",
        state: "",
        country: "",
        address: "",
        zipcode: "",
        regions: {
          "tier-1": "",
          "tier-2": "",
          "tier-3": "",
        },
      },
      contact: {
        name: "",
        title: "",
        email: "",
        phone: "",
      },
      collectionComment: "",
      acknowledgement: false,
      imageAcknowledgement: false,
      // user: { firstName: "", lastName: "" },
      // submitDate: new Date().toLocaleDateString("en-CA"),
    };
  }

  render() {
    const { logoutCallback } = this.props;
    return (
      <>
        <div id="wrapper">
          <Header />
          <div id="main">
            <section
              id="banner"
              className="style3"
              style={{ marginTop: "100px" }}
            >
              <div className="inner">
                <span className="image">
                  <img src={pic7} alt="" />
                </span>
                <header className="major">
                  <h1>Current Archives and Collections Index</h1>
                </header>
                <div className="content">
                  <p>
                    Please fill out the form below as completely as possible.
                    The more accurate the information, the greater the
                    likelihood that we will be able to connect users to your
                    collection.
                  </p>
                </div>
              </div>
            </section>
            <section id="four">
              <Form formName="insert-collection" collection={this.state} />
            </section>
          </div>
          <Footer />
        </div>
        <Menu logoutCallback={logoutCallback} />
      </>
    );
  }
}

export default InsertForm;
