/* eslint-disable import/no-anonymous-default-export */
export default {
  selectedCategory: "contributor",
  trending: [],
  infoSectionData: {
    topic: {
      directory: [],
      trending: [],
    },
    location: {
      directory: [],
      trending: [],
    },
    period: {
      directory: [],
      trending: [],
    },
    contributor: {
      directory: [],
      trending: [],
    },
  },
  search: {
    options: [],
    selectedOption: "",
  },
};
