import React from "react";
import { Link } from "react-router-dom";
import Article from "../Article/Article";
import Comment from "../Comment/Comment";
import pic1 from "../../images/pic01.jpg";
// import pic2 from "../../images/pic02.jpg";
// import pic3 from "../../images/pic03.jpg";
import pic4 from "../../images/pic04.jpg";
import { renderCategoryList } from "../../assets/js/utils";

export const renderArticles = (context) => {
  const { trending } = context.state;
  return (
    <>
      <Article>
        <span className="image">
          <img src={pic1} alt="" />
        </span>
        <header className="major">
          <h3>
            <Link to="/novelgeneratormachine">Trending Now</Link>
          </h3>
          <ul
            id="global-trending"
            style={{
              maxHeight: "308px",
              overflow: "auto",
            }}
          >
            {renderCategoryList(trending)}
          </ul>
        </header>
      </Article>
      <Article>
        <span className="image">
          <img src={pic4} alt="" />
        </span>
        <header className="major">
          <h3>We Need Your Help...</h3>
          <p>
            <Comment
              type="global"
              heading="Our index is only as strong as our users make it. Please give us your feedback:"
            />
          </p>
        </header>
      </Article>
      {/* <Article>
        <span className="image">
          <img src={pic2} alt="" />
        </span>
        <header className="major">
          <h3>CACI's Weekly Feature</h3>
          <p>Coming Soon...</p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </header>
      </Article>
      <Article>
        <span className="image">
          <img src={pic3} alt="" />
        </span>
        <header className="major">
          <h3>
            <Link to="/remarkablereceptions" className="link">
              Staff Picks
            </Link>
          </h3>
          <div>
            <ul style={{ maxHeight: "308px", overflow: "auto" }}>
              <li>
                Coming Soon...
                <br />
                "Staff Quote"
              </li>
            </ul>
          </div>
        </header>
      </Article> */}
    </>
  );
};

export const renderRadioButton = (context, category, label, clickHandler) => {
  const { selectedCategory } = context.state;
  return (
    <div className="col-12 col-12-small">
      <input
        type="radio"
        id={`caci-top-${category}`}
        name="caci-top"
        onClick={clickHandler.bind(context, category)}
        autoComplete="off"
        checked={category === selectedCategory}
        readOnly
      />
      <label htmlFor={`caci-top-${category}`}>{label}</label>
    </div>
  );
};
