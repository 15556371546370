import React from "react";
import Comment from "../Comment/Comment";
import { renderCategoryList } from "../../assets/js/utils";

const renderBasicInfo = (type, { directory, trending }) => (
  <div className="row" id={`caci-${type}-div`}>
    <div className="col-6 col-12-small">
      <div
        style={{
          border: "1px solid",
          padding: "5px",
          marginTop: "5px",
        }}
      >
        <h3 style={{ textTransform: "capitalize" }}>{type} Directory</h3>
        <ul
          style={{
            maxHeight: "308px",
            overflow: "auto",
          }}
          id={`${type}-directory`}
        >
          {renderCategoryList(directory, true)}
        </ul>
      </div>
    </div>
    <div className="col-6 col-12-small">
      <div
        style={{
          border: "1px solid",
          padding: "5px",
          marginTop: "5px",
        }}
      >
        <h3 style={{ textTransform: "capitalize" }}>{`Trending ${type}s`}</h3>
        <ol id={`trending-${type}s`}>{renderCategoryList(trending)}</ol>
      </div>
      <div
        style={{
          border: "1px solid",
          padding: "5px",
          marginTop: "5px",
        }}
      >
        <Comment
          type={type}
          heading={`Don't see the ${type} you're looking for? Tell us about it!`}
        />
      </div>
    </div>
  </div>
);

export default renderBasicInfo;
