/* eslint-disable import/extensions */
import React from "react";
// eslint-disable-next-line no-unused-vars
import $ from "jquery";
import "../../assets/js/jquery.scrolly.min.js";
import "../../assets/js/jquery.scrollex.min.js";
import "../../assets/js/util.js";
import "../../assets/js/main.js";

function Footer() {
  return (
    <footer id="footer">
      <div className="inner">
        <ul className="copyright">
          <li>© BlackLit Online</li>
          <li>
            Design:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.html5up.net"
            >
              HTML5 UP
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
