/* eslint-disable */
import $ from "jquery";
import breakpoints from "breakpoints-js";
/*
	Forty by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

var browser = (function () {
  "use strict";
  var e = {
    name: null,
    version: null,
    os: null,
    osVersion: null,
    touch: null,
    mobile: null,
    _canUse: null,
    canUse: function (n) {
      e._canUse || (e._canUse = document.createElement("div"));
      var o = e._canUse.style,
        r = n.charAt(0).toUpperCase() + n.slice(1);
      return (
        n in o ||
        "Moz" + r in o ||
        "Webkit" + r in o ||
        "O" + r in o ||
        "ms" + r in o
      );
    },
    init: function () {
      var n,
        o,
        r,
        i,
        t = navigator.userAgent;
      for (
        n = "other",
          o = 0,
          r = [
            ["firefox", /Firefox\/([0-9\.]+)/],
            ["bb", /BlackBerry.+Version\/([0-9\.]+)/],
            ["bb", /BB[0-9]+.+Version\/([0-9\.]+)/],
            ["opera", /OPR\/([0-9\.]+)/],
            ["opera", /Opera\/([0-9\.]+)/],
            ["edge", /Edge\/([0-9\.]+)/],
            ["safari", /Version\/([0-9\.]+).+Safari/],
            ["chrome", /Chrome\/([0-9\.]+)/],
            ["ie", /MSIE ([0-9]+)/],
            ["ie", /Trident\/.+rv:([0-9]+)/],
          ],
          i = 0;
        i < r.length;
        i++
      )
        if (t.match(r[i][1])) {
          (n = r[i][0]), (o = parseFloat(RegExp.$1));
          break;
        }
      for (
        e.name = n,
          e.version = o,
          n = "other",
          o = 0,
          r = [
            [
              "ios",
              /([0-9_]+) like Mac OS X/,
              function (e) {
                return e.replace("_", ".").replace("_", "");
              },
            ],
            [
              "ios",
              /CPU like Mac OS X/,
              function (e) {
                return 0;
              },
            ],
            ["wp", /Windows Phone ([0-9\.]+)/, null],
            ["android", /Android ([0-9\.]+)/, null],
            [
              "mac",
              /Macintosh.+Mac OS X ([0-9_]+)/,
              function (e) {
                return e.replace("_", ".").replace("_", "");
              },
            ],
            ["windows", /Windows NT ([0-9\.]+)/, null],
            ["bb", /BlackBerry.+Version\/([0-9\.]+)/, null],
            ["bb", /BB[0-9]+.+Version\/([0-9\.]+)/, null],
            ["linux", /Linux/, null],
            ["bsd", /BSD/, null],
            ["unix", /X11/, null],
          ],
          i = 0;
        i < r.length;
        i++
      )
        if (t.match(r[i][1])) {
          (n = r[i][0]),
            (o = parseFloat(r[i][2] ? r[i][2](RegExp.$1) : RegExp.$1));
          break;
        }
      (e.os = n),
        (e.osVersion = o),
        (e.touch =
          "wp" == e.os
            ? navigator.msMaxTouchPoints > 0
            : !!("ontouchstart" in window)),
        (e.mobile =
          "wp" == e.os || "android" == e.os || "ios" == e.os || "bb" == e.os);
    },
  };
  return e.init(), e;
})();

(function ($) {
  var $window = $(window);
  $window.on("load", function () {
    var $body = $("body"),
      $wrapper = $("#wrapper"),
      $header = $("#header"),
      $banner = $("#banner");

    // Breakpoints.
    breakpoints({
      xlarge: ["1281px", "1680px"],
      large: ["981px", "1280px"],
      medium: ["737px", "980px"],
      small: ["481px", "736px"],
      xsmall: ["361px", "480px"],
      xxsmall: [null, "360px"],
    });

    /**
     * Applies parallax scrolling to an element's background image.
     * @return {jQuery} jQuery object.
     */
    $.fn._parallax =
      browser.name == "ie" || browser.name == "edge" || browser.mobile
        ? function () {
            return $(this);
          }
        : function (intensity) {
            var $window = $(window),
              $this = $(this);

            if (this.length == 0 || intensity === 0) return $this;

            if (this.length > 1) {
              for (var i = 0; i < this.length; i++)
                $(this[i])._parallax(intensity);

              return $this;
            }

            if (!intensity) intensity = 0.25;

            $this.each(function () {
              var $t = $(this),
                on,
                off;

              on = function () {
                $t.css(
                  "background-position",
                  "center 100%, center 100%, center 0px",
                );

                $window.on("scroll._parallax", function () {
                  var pos =
                    parseInt($window.scrollTop()) - parseInt($t.position().top);

                  $t.css(
                    "background-position",
                    "center " + pos * (-1 * intensity) + "px",
                  );
                });
              };

              off = function () {
                $t.css("background-position", "");

                $window.off("scroll._parallax");
              };

              breakpoints.on("<=medium", off);
              breakpoints.on(">medium", on);
            });

            $window
              .off("load._parallax resize._parallax")
              .on("load._parallax resize._parallax", function () {
                $window.trigger("scroll");
              });

            return $(this);
          };

    window.setTimeout(function () {
      $body.removeClass("is-preload");
    }, 100);
    // Tiles.
    var $tiles = $(".tiles > article");

    $tiles.each(function () {
      var $this = $(this),
        $image = $this.find(".image"),
        $img = $image.find("img"),
        $link = $this.find(".link"),
        x;

      // Image.

      // Set image.
      $this.css("background-image", "url(" + $img.attr("src") + ")");

      // Set position.
      if ((x = $img.data("position"))) $image.css("background-position", x);

      // Hide original.
      $image.hide();

      // Link.
      if ($link.length > 0) {
        var $x = $link.clone().text("").addClass("primary").appendTo($this);

        $link = $link.add($x);

        $link.on("click", function (event) {
          var href = $link.attr("href");

          // Prevent default.
          event.stopPropagation();
          event.preventDefault();

          // Target blank?
          if ($link.attr("target") == "_blank") {
            // Open in new tab.
            window.open(href);
          }

          // Otherwise ...
          else {
            // Start transitioning.
            $this.addClass("is-transitioning");
            $wrapper.addClass("is-transitioning");

            // Redirect.
            window.setTimeout(function () {
              location.href = href;
            }, 500);
          }
        });
      }
    });

    // Clear transitioning state on unload/hide.
    $window.on("unload pagehide", function () {
      window.setTimeout(function () {
        $(".is-transitioning").removeClass("is-transitioning");
      }, 250);
    });

    // Fix: Enable IE-only tweaks.
    if (browser.name == "ie" || browser.name == "edge") $body.addClass("is-ie");

    // Scrolly.
    $(".scrolly").scrolly({
      offset: function () {
        return $header.height() - 2;
      },
    });

    // Header.
    if ($banner.length > 0 && $header.hasClass("alt")) {
      $window.on("resize", function () {
        $window.trigger("scroll");
      });

      $banner.scrollex({
        bottom: $header.height() + 10,
        terminate: function () {
          $header.removeClass("alt");
        },
        enter: function () {
          $header.addClass("alt");
        },
        leave: function () {
          $header.removeClass("alt");
          $header.addClass("reveal");
        },
      });

      window.setTimeout(function () {
        $window.triggerHandler("scroll");
      }, 100);
    }

    // Banner.
    $banner.each(function () {
      var $this = $(this),
        $image = $this.find(".image"),
        $img = $image.find("img");

      // Parallax.
      $this._parallax(0.275);

      // Image.
      if ($image.length > 0) {
        // Set image.
        $this.css("background-image", "url(" + $img.attr("src") + ")");

        // Hide original.
        $image.hide();
      }
    });
  });
})($);
