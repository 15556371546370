/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import $ from "jquery";
import React from "react";
import ReactPaginate from "react-paginate";
import { postWrapper } from "../../utils/axiosUtils";
import notyfWrapper from "../../utils/notyfUtils";
import withRouter from "../../utils/routerUtils";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import "./AdminHome.css";

const renderTitle = (collection, view) => (
  <td>
    <div className="data-cell">
      <a
        title={collection.collectionTitle}
        href={
          view === "pending"
            ? `approve-collection?id=${collection._id}`
            : `update-collection?id=${collection._id}`
        }
      >
        {collection.collectionTitle}
      </a>
    </div>
  </td>
);

class AdminHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      searchQuery: {
        query: "",
        filter: "0",
      },
      searchResults: [],
      repoList: [],
      view: "active",
      sortBy: "",
      orderBy: "",
      paginate: {
        page: 1,
        size: 10,
        props: {
          pageCount: 1,
          previousLabel: "Previous",
          nextLabel: "Next",
          pageClassName: "page-item",
          pageLinkClassName: "page-link",
          previousClassName: "page-item",
          previousLinkClassName: "page-link",
          nextClassName: "page-item",
          nextLinkClassName: "page-link",
          breakLabel: "...",
          breakClassName: "page-item",
          breakLinkClassName: "page-link",
          containerClassName: "pagination",
          activeClassName: "active",
        },
      },
      totalCollections: 0,
    };
  }

  componentDidMount() {
    this.notyf = notyfWrapper.getInstance();
    this.getCollections();
    this.getRepos();
  }

  componentDidUpdate(prevProps, prevState) {
    const { view, sortBy, orderBy, collections, searchResults } = this.state;
    if (
      view !== prevState.view ||
      sortBy !== prevState.sortBy ||
      orderBy !== prevState.orderBy
    ) {
      const {
        searchQuery: { query, filter },
        paginate: { page, size },
      } = this.state;
      if (query || filter !== "0") {
        this.getSearchResults(
          {
            ...(query ? { query } : { filter }),
            sortBy,
            orderBy,
            page,
            size,
          },
          (obj) => {
            this.setState({
              // eslint-disable-next-line react/no-access-state-in-setstate
              ...this.state,
              ...obj,
            });
          },
        );
      } else {
        this.getCollections({
          page: 1,
          size: 10,
        });
      }
    }
    if (
      collections !== prevState.collections ||
      searchResults !== prevState.searchResults
    ) {
      const self = this;
      // eslint-disable-next-line func-names
      $(".header-cell").click(function () {
        const isSortedAsc = $(this).hasClass("sort-asc");
        const isSortedDesc = $(this).hasClass("sort-desc");
        const isUnsorted = !isSortedAsc && !isSortedDesc;
        // eslint-disable-next-line no-shadow
        let sortBy = "";
        // eslint-disable-next-line no-shadow
        let orderBy = "";

        switch ($(this)[0].innerText) {
          case "Collection Title":
            sortBy = "collectionTitle";
            break;
          case "Collection Repository":
            sortBy = "collectionRepository";
            break;
          case "Contact Name":
            sortBy = "contact.name";
            break;
          default:
            break;
        }

        if (isUnsorted || isSortedDesc) {
          orderBy = 1;
        } else if (isSortedAsc) {
          orderBy = -1;
        }
        if (sortBy && orderBy) {
          self.setState({
            sortBy,
            orderBy,
          });
        }
      });
    }
  }

  getCollections = (reqObj, stateObj = {}) => {
    const { view, sortBy, orderBy, paginate } = this.state;
    const { page, size } = reqObj || paginate;
    const { logoutCallback } = this.props;
    postWrapper(
      `/api/${view}-collections`,
      {
        sortBy,
        orderBy,
        page,
        size,
      },
      (
        response = {
          docs: [],
        },
      ) => {
        paginate.props.pageCount = response.totalPages;
        this.setState({
          collections: response.docs,
          totalCollections: response.totalDocs,
          paginate: { ...paginate },
          page: response.page,
          ...stateObj,
        });
      },
      ({ status }) => {
        if (status === 401) {
          localStorage.removeItem("profile");
          logoutCallback();
        }
      },
    );
  };

  getRepos = () => {
    postWrapper(
      "/api/distinct",
      {
        fieldName: "collectionRepository",
      },
      (repoList = []) => {
        this.setState({ repoList });
      },
    );
  };

  getSearchResults = (reqObj, callback = () => {}) => {
    postWrapper("/api/admin-search", reqObj, (response = {}) => {
      const { paginate } = this.state;
      paginate.props.pageCount = response.totalPages;
      if (response.docs.length) {
        callback({
          searchResults: response.docs,
          page: response.page,
          paginate: { ...paginate },
        });
      } else {
        this.notyf.success("No collections found!");
        callback({
          paginate: { ...paginate },
          page: response.page,
          searchQuery: {
            query: "",
            filter: "0",
          },
        });
      }
    });
  };

  search = (event) => {
    let value = "";
    const { sortBy, orderBy } = this.state;
    switch (event.target.tagName) {
      case "SELECT":
        value = event.target.value;
        if (value) {
          this.getSearchResults(
            {
              filter: value,
              sortBy,
              orderBy,
              page: 1,
              size: 10,
            },
            (obj) => {
              this.setState((prevState) => ({
                ...prevState,
                searchQuery: {
                  query: "",
                  filter: value,
                },
                ...obj,
              }));
            },
          );
        }
        break;
      case "BUTTON":
        // eslint-disable-next-line no-case-declarations
        const inputEle = document.getElementById("search-text");
        value = inputEle.value.trim();
        if (value) {
          inputEle.value = "";
          this.getSearchResults(
            {
              query: value,
              sortBy,
              orderBy,
              page: 1,
              size: 10,
            },
            (obj) => {
              this.setState((prevState) => ({
                ...prevState,
                searchQuery: {
                  query: value,
                  filter: "0",
                },
                ...obj,
              }));
            },
          );
        }
        break;
      default:
        break;
    }
  };

  approveCollection = (id) => {
    const {
      router: { navigate },
    } = this.props;
    navigate(`/caci/approve-collection?id=${id}`);
  };

  editCollection = (id) => {
    const {
      router: { navigate },
    } = this.props;
    navigate(`/caci/update-collection?id=${id}`);
  };

  // viewCollection = (id) => {
  //   const {
  //     router: { navigate },
  //   } = this.props;
  //   navigate(`/caci-approval/detail.php?id=${id}`);
  // };

  archiveCollection = (_id) => {
    if (confirm("This collection will be archived")) {
      let { collections } = this.state;
      postWrapper(
        "/api/archive",
        {
          _id,
          state: "archived",
        },
        () => {
          this.notyf.success("The collection has been successfully Archived!");
          collections = collections.filter((obj) => obj._id !== _id);
          this.setState({ collections });
        },
      );
    }
  };

  unArchiveCollection = (_id) => {
    if (confirm("This collection will be moved to pending state!")) {
      postWrapper(
        "/api/archive",
        {
          _id,
          state: "pending",
        },
        () => {
          this.notyf.success(
            "The collection has been successfully moved to pending state!",
          );
          let { collections } = this.state;
          collections = collections.filter((obj) => obj._id !== _id);
          this.setState({ collections });
        },
      );
    }
  };

  deleteCollection = (_id, photos) => {
    if (confirm("This collection will be premanently deleted!")) {
      postWrapper(
        "/api/delete-collection",
        {
          collectionPhotos: photos && photos.length ? photos : [],
          _id,
        },
        () => {
          this.notyf.success("The collection has been Successfully Deleted!");
          let { collections } = this.state;
          collections = collections.filter((obj) => obj._id !== _id);
          this.setState({ collections });
        },
      );
    }
  };

  renderListActions = (collection, view) => {
    const actionsData = [
      {
        class: "fa-pen-to-square",
        name: "approve",
      },
      {
        class: "fa-eye",
        name: "view",
      },
      {
        class: "fa-box-archive",
        name: "archive",
      },
      {
        class: "fa-pen-to-square",
        name: "edit",
      },
      {
        class: "fa-box-open",
        name: "unArchive",
      },
      {
        class: "fa-trash",
        name: "delete",
      },
    ];
    let actions;
    switch (view) {
      case "pending":
        actions = [actionsData[0], actionsData[2]];
        break;
      case "archived":
        actions = [actionsData[3], actionsData[4], actionsData[5]];
        break;
      default:
        actions = [actionsData[3], actionsData[2]];
    }
    return (
      <div className="data-cell">
        {actions.map((action, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <i
            role="button"
            tabIndex={0}
            // eslint-disable-next-line react/no-array-index-key
            key={view + action.name + index}
            className={`fa fa-solid alt ${action.class} c-p`}
            style={{ margin: "5px" }}
            title={`${action.name} this collection`}
            onClick={this[`${action.name}Collection`].bind(
              this,
              collection._id,
            )}
          />
        ))}
      </div>
    );
  };

  renderContent = (data) => {
    if (data.length > 0) {
      const { view, sortBy, orderBy } = this.state;
      const content = [];
      content.push(
        <thead key="table-header">
          <tr>
            <th
              className={`header-cell${
                sortBy === "collectionTitle"
                  ? ` sort-${orderBy === 1 ? "asc" : "desc"}`
                  : ""
              }`}
            >
              Collection Title
            </th>
            <th
              className={`header-cell${
                sortBy === "collectionRepository"
                  ? ` sort-${orderBy === 1 ? "asc" : "desc"}`
                  : ""
              }`}
            >
              Collection Repository
            </th>
            <th
              className={`header-cell${
                sortBy === "contact.name"
                  ? ` sort-${orderBy === 1 ? "asc" : "desc"}`
                  : ""
              }`}
            >
              Contact Name
            </th>
            <th>Contact Email</th>
            <th>Contact Phone</th>
            <th>Actions</th>
          </tr>
        </thead>,
      );
      content.push(
        <tbody key="table-body">
          {data.map((collection) => (
            <tr key={collection._id}>
              {renderTitle(collection, view)}
              <td>
                <div
                  className="data-cell"
                  title={collection.collectionRepository}
                >
                  {collection.collectionRepository}
                </div>
              </td>
              <td>
                <div className="data-cell" title={collection.contact.name}>
                  {collection.contact.name}
                </div>
              </td>
              <td>
                <div className="data-cell" title={collection.contact.email}>
                  {collection.contact.email}
                </div>
              </td>
              <td>
                <div className="data-cell" title={collection.contact.phone}>
                  {collection.contact.phone}
                </div>
              </td>
              <td>{this.renderListActions(collection, view)}</td>
            </tr>
          ))}
        </tbody>,
      );
      return <table>{content}</table>;
    }
    return <h2>No Collections Found.</h2>;
  };

  handlePageChange = (event) => {
    const { paginate } = this.state;
    this.setState(
      { paginate: { ...paginate, page: event.selected + 1 } },
      () => {
        const {
          searchQuery: { query, filter },
          paginate: { page, size },
          sortBy,
          orderBy,
        } = this.state;
        if (query || filter !== "0") {
          this.getSearchResults(
            {
              ...(query ? { query } : { filter }),
              sortBy,
              orderBy,
              page,
              size,
            },
            (obj) => {
              this.setState((prevState) => ({
                ...prevState,
                ...obj,
              }));
            },
          );
        } else {
          this.getCollections();
        }
      },
    );
  };

  resetSearchResults() {
    this.getCollections(
      {
        page: 1,
        size: 10,
      },
      {
        searchQuery: {
          query: "",
          filter: "0",
        },
        searchResults: [],
      },
    );
  }

  renderPagination = (page, props) => (
    <ReactPaginate
      {...props}
      forcePage={page - 1}
      onPageChange={this.handlePageChange}
    />
  );

  render() {
    const {
      collections,
      repoList,
      view,
      searchResults,
      searchQuery: { filter },
      page,
      paginate: { props },
      totalCollections = 0,
    } = this.state;
    const {
      router: { navigate },
      username,
      logoutCallback,
    } = this.props;
    return (
      <>
        <div id="wrapper">
          <Header callback={this.resetSearchResults.bind(this)} />
          {searchResults.length ? (
            <>
              <div className="list-header">
                <h1 style={{ margin: "1.5rem" }}>Search Results:</h1>
              </div>
              <div id="view" className="tabcontent">
                <div style={{ overflowX: "auto", margin: "1rem" }}>
                  {this.renderContent(searchResults)}
                </div>
                {searchResults.length > 0 && this.renderPagination(page, props)}
              </div>
            </>
          ) : (
            <>
              <div className="list-header">
                <h1
                  style={{
                    margin: "1.5rem",
                    maxWidth: "40%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textTransform: "capitalize",
                  }}
                >
                  Welcome, <b title={username}>{username}</b>
                </h1>
                <div
                  className="col-12 col-12-xsmall"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <select
                    id="date_filter"
                    onChange={this.search}
                    value={filter}
                  >
                    <option value="0">Select</option>
                    <option value="1">Today</option>
                    <option value="2">This Week</option>
                    <option value="3">Last week</option>
                    <option value="4">This Month</option>
                    <option value="5">Last Month</option>
                    <option value="6">Last 3 Months</option>
                    <option value="7">Last 6 Months</option>
                    <option value="8">This Year</option>
                    <option value="9">Last Year</option>
                  </select>
                  <span style={{ marginLeft: "10px" }}>(OR)</span>
                  <input
                    type="text"
                    id="search-text"
                    placeholder="Search Text"
                    style={{ marginLeft: "10px" }}
                  />
                  <button
                    type="button"
                    id="period-search"
                    style={{ marginLeft: "10px", height: "3.35em" }}
                    onClick={this.search}
                  >
                    Search
                  </button>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/caci/insert-collection");
                  }}
                >
                  Add Collection
                </button>
              </div>
              <div className="list-header">
                {repoList.length > 0 && (
                  <h2 style={{ marginLeft: "1.5rem" }}>
                    Total Repositories: {repoList.length}
                  </h2>
                )}
              </div>
              <div className="tab">
                <button
                  type="button"
                  className={`tablinks ${view === "active" ? "active" : ""}`}
                  onClick={() => this.setState({ view: "active" })}
                  id="active-button"
                >
                  Active Collections{" "}
                  {view === "active" ? `( ${totalCollections} )` : ""}
                </button>
                <button
                  type="button"
                  className={`tablinks ${view === "pending" ? "active" : ""}`}
                  onClick={() => this.setState({ view: "pending" })}
                  id="pending-button"
                >
                  Pending Collections{" "}
                  {view === "pending" ? `( ${totalCollections} )` : ""}
                </button>
                <button
                  type="button"
                  className={`tablinks ${view === "archived" ? "active" : ""}`}
                  onClick={() => this.setState({ view: "archived" })}
                  id="archived-button"
                >
                  Archived Collections{" "}
                  {view === "archived" ? `( ${totalCollections} )` : ""}
                </button>
              </div>
              <div id="view" className="tabcontent">
                <div style={{ overflowX: "auto", margin: "1rem" }}>
                  {this.renderContent(collections)}
                </div>
                {collections.length > 0 && this.renderPagination(page, props)}
              </div>
            </>
          )}
          <Footer />
        </div>
        <Menu logoutCallback={logoutCallback} />
      </>
    );
  }
}

export default withRouter(AdminHome);
