/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem("profile"));
  }

  clickHandler = () => {
    const { callback = () => {} } = this.props;
    callback();
  };

  render() {
    const { user } = this;
    return (
      <header id="header" className="alt style2">
        <Link
          to={user ? "/caci/admin-list" : "/"}
          className="logo"
          onClick={this.clickHandler}
        >
          <strong>BlackLit</strong> <span>Online</span>
        </Link>
        <nav>
          <span
            role="button"
            tabIndex={0}
            className="menu"
            onClick={() => {
              document.body.classList.add("is-menu-visible");
            }}
          >
            Menu
          </span>
        </nav>
      </header>
    );
  }
}

export default Header;
