import { updateCountryStateCityData } from "../../assets/js/utils";

export const handleLocationChange = (context, name, selectedOption) => {
  if (name === "country") {
    context.setState({
      collection: {
        ...context.state.collection,
        location: {
          ...context.state.collection.location,
          [name]: selectedOption,
          state: "",
          city: "",
        },
      },
      locationSelect: {
        ...context.state.locationSelect,
        states: [],
        cities: [],
      },
    });
    updateCountryStateCityData(
      context,
      "states",
      `/${selectedOption.value}/states`,
      (result) => {
        const options = [];
        JSON.parse(result).forEach((obj) => {
          options.push({
            label: obj.name,
            value: obj.iso2 || obj.name,
          });
        });
        context.setState({
          locationSelect: {
            ...context.state.locationSelect,
            states: options,
          },
        });
      },
    );
  } else if (name === "state") {
    context.setState({
      collection: {
        ...context.state.collection,
        location: {
          ...context.state.collection.location,
          [name]: selectedOption,
          city: "",
        },
      },
      locationSelect: {
        ...context.state.locationSelect,
        cities: [],
      },
    });
    const {
      location: {
        country: { value },
      },
    } = context.state.collection;
    updateCountryStateCityData(
      context,
      "cities",
      `/${value}/states/${selectedOption.value}/cities`,
      (result) => {
        const options = [];
        JSON.parse(result).forEach((obj) => {
          options.push({
            label: obj.name,
            value: obj.iso2 || obj.name,
          });
        });
        context.setState({
          locationSelect: {
            ...context.state.locationSelect,
            cities: options,
          },
        });
      },
    );
  } else if (name === "city") {
    context.setState({
      collection: {
        ...context.state.collection,
        location: {
          ...context.state.collection.location,
          [name]: selectedOption,
        },
      },
    });
  }
};

export const handleInputChange = (context, inputName, event) => {
  const { value } = event.target;
  const inputNameTrimmed = inputName.split(".");
  if (inputNameTrimmed.length === 1) {
    context.setState({
      collection: {
        ...context.state.collection,
        [inputNameTrimmed[0]]: value,
      },
    });
  } else if (inputNameTrimmed.length === 2) {
    context.setState({
      collection: {
        ...context.state.collection,
        [inputNameTrimmed[0]]: {
          ...context.state.collection[inputNameTrimmed[0]],
          [inputNameTrimmed[1]]: value,
        },
      },
    });
  }
};

// export const handleCheckboxChange = (context, name, event) => {
//   let value = event.target.checked;
//   context.setState({
//     collection: {
//       ...context.state.collection,
//       [name]: value,
//     },
//   });
// };

export const handleFormatChange = (context, value, event) => {
  const { collection } = context.state;
  let { collectionFormat } = collection;
  if (event.target.checked) {
    collectionFormat.push(value);
  } else {
    collectionFormat = collectionFormat.filter((item) => item !== value);
  }
  context.setState({
    collection: {
      ...collection,
      collectionFormat,
    },
  });
};

export const handleSelectChange = (context, name, selectedOptions) => {
  context.setState({
    collection: {
      ...context.state.collection,
      [name]: selectedOptions,
    },
  });
};

export const handleContributorTypeChange = (context, key, event) => {
  const { collection } = context.state;
  const { collectionContributor } = collection;
  let keepGoing = true;
  for (let i = 0; i < collectionContributor.length; i += 1) {
    if (collectionContributor[i].key === key) {
      switch (event.target.value) {
        case "":
          collectionContributor[i].type.label = "Select";
          collectionContributor[i].type.value = "";
          keepGoing = false;
          break;
        case "author":
          collectionContributor[i].type.label = "Author";
          collectionContributor[i].type.value = "author";
          keepGoing = false;
          break;
        case "public-figure":
          collectionContributor[i].type.label = "Public Figure";
          collectionContributor[i].type.value = "public-figure";
          keepGoing = false;
          break;
        case "literary-author":
          collectionContributor[i].type.label = "Literary Author";
          collectionContributor[i].type.value = "literary-author";
          keepGoing = false;
          break;
        case "organization":
          collectionContributor[i].type.label = "Organization";
          collectionContributor[i].type.value = "organization";
          keepGoing = false;
          break;
        case "community-leader":
          collectionContributor[i].type.label = "Community Leader";
          collectionContributor[i].type.value = "community-leader";
          keepGoing = false;
          break;
        // case "other":
        //   collectionContributor[i].type.label = "other";
        //   collectionContributor[i].type.value = "other";
        //   keepGoing = false;
        // break;
        default:
          break;
      }
      if (!keepGoing) break;
    }
  }
  context.setState({
    collection: {
      ...collection,
      collectionContributor,
    },
  });
};

export const handleContributorValueChange = (context, key, event) => {
  const { collection } = context.state;
  const { collectionContributor } = collection;
  for (let i = 0; i < collectionContributor.length; i += 1) {
    if (collectionContributor[i].key === key) {
      collectionContributor[i].value = event.target.value;
      break;
    }
  }
  context.setState({
    collection: {
      ...collection,
      collectionContributor,
    },
  });
};
