/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { postWrapper } from "../../utils/axiosUtils";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import Comment from "../Comment/Comment";
import pic7 from "../../images/pic07.jpg";

const getRequestObj = () => {
  const url = new URL(window.location.href);
  const category = url.searchParams.get("category") || "";
  const value = url.searchParams.get("value") || "";
  const from = url.searchParams.get("from") || "";
  const to = url.searchParams.get("to") || "";
  const sortBy = url.searchParams.get("sortBy") || "collectionTitle";
  let orderBy = url.searchParams.get("orderBy") || "asc";
  orderBy = orderBy === "asc" ? 1 : -1;
  return {
    category,
    value,
    from,
    to,
    sortBy,
    orderBy,
  };
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      queryObj: getRequestObj(),
      fetching: true,
    };
  }

  componentDidMount() {
    const { queryObj } = this.state;
    postWrapper("api/results", queryObj, (collections = []) => {
      this.setState({ collections, fetching: false });
    });
  }

  renderContent() {
    const { collections, fetching } = this.state;
    if (fetching) {
      return "Please wait while we fetch the search results...";
    }
    if (collections.length > 0) {
      return collections.map((collection) => (
        <div className="col-12 col-12-small" key={collection._id}>
          <Link to={`/caci-collection?id=${collection._id}`}>
            {collection.collectionTitle}
          </Link>
          <p className="text-ellipsis--4" style={{ paddingLeft: "1.5rem" }}>
            {collection.collectionDescription}
          </p>
        </div>
      ));
    }
    return <div className="col-12 col-12-small">No Collections Found!</div>;
  }

  render() {
    const {
      queryObj: { value },
    } = this.state;
    const { logoutCallback } = this.props;
    return (
      <>
        <div id="wrapper">
          <Header />
          <section id="banner" className="style2">
            <div className="inner">
              <span className="image">
                <img src={pic7} alt="" />
              </span>
              <header className="major">
                <h1>Current Archives and Collections Index</h1>
              </header>
              <div className="content">
                <p>Your quick guide to collections on your favorite topics</p>
              </div>
            </div>
          </section>
          <div id="main">
            <section id="two">
              <div className="inner">
                <header className="major">
                  <h2>
                    Search Results for
                    {` ${value}`}
                  </h2>
                </header>
                <div className="row">
                  <div className="col-8 col-12-small">
                    <div style={{ border: "1px solid", padding: "5px" }}>
                      {this.renderContent()}
                    </div>
                  </div>
                  <div className="col-4 col-12-small">
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <Link to="/">Author Works</Link>
                    </div>
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <Link to="/">
                        Podcasts on
                        {` ${value}`}
                      </Link>
                    </div>
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <Link to="/">
                        Data Visualizations on
                        {` ${value}`}
                      </Link>
                    </div>
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <Link to="/">
                        Literary Briefs on
                        {` ${value}`}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-12-small">
                    <div style={{ padding: "5px", marginTop: "5px" }}>
                      <Comment
                        type="results"
                        heading="We're learning about new collections all the time. Do you know of one that you don't see in our list? Please tell us about it - and thanks!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
        <Menu logoutCallback={logoutCallback} />
      </>
    );
  }
}

export default Home;
