import React from "react";
import { postWrapper } from "../../utils/axiosUtils";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import Form from "../Form/Form";

const getRequestObj = () => {
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id") || "";
  return {
    _id: id,
  };
};

class UpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadForm: false,
      collection: {},
      queryObj: getRequestObj(),
    };
  }

  componentDidMount() {
    const { queryObj } = this.state;
    postWrapper("/api/collection", queryObj, (collection = {}) => {
      collection.collectionContributor.forEach((contributor, index) => {
        // eslint-disable-next-line no-param-reassign
        collection.collectionContributor[
          index
        ].key = `contributor_${Math.random().toString(16).slice(2)}`;
      });
      this.setState({ collection, loadForm: true });
    });
  }

  render() {
    const { loadForm, collection } = this.state;
    const { logoutCallback } = this.props;
    return (
      <>
        <div id="wrapper">
          <Header />
          <div id="main">
            <section id="four">
              {loadForm && (
                <Form
                  formName="update-collection"
                  collection={{ ...collection }}
                />
              )}
            </section>
          </div>
          <Footer />
        </div>
        <Menu logoutCallback={logoutCallback} />
      </>
    );
  }
}

export default UpdateForm;
