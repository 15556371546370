import React from "react";
import { postWrapper } from "../../utils/axiosUtils";
import notyfWrapper from "../../utils/notyfUtils";

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.notyf = notyfWrapper.getInstance();
    this.state = {
      comment: "",
    };
  }

  sendComment = () => {
    let { comment } = this.state;
    comment = comment.trim();
    const { type } = this.props;
    if (comment) {
      postWrapper(
        "api/insert-comment",
        {
          comment,
          type,
        },
        () => {
          this.notyf.success("Your comment has been saved successfully!");
          this.resetComment();
        },
      );
    }
  };

  resetComment = () => {
    this.setState({ comment: "" });
  };

  handleChange = (event) => {
    this.setState({ comment: event.target.value });
  };

  render() {
    const { heading } = this.props;
    const { comment } = this.state;
    return (
      <>
        {heading}
        <textarea
          name="feedback-msg"
          placeholder="(Comments)"
          rows={7}
          value={comment}
          onChange={this.handleChange}
        />
        <input
          type="button"
          defaultValue="Send Comment"
          onClick={this.sendComment}
          style={{ marginTop: "0.25em" }}
        />
      </>
    );
  }
}

export default Comment;
