/* eslint-disable no-unused-vars */
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // error reporting
  }

  render() {
    const { info, children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <h1>
          Something went wrong
          {info}. Please reload the browser.
        </h1>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
