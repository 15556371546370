import React from "react";
import renderBasicInfo from "./renderUtils";
import renderRegionInfo from "./renderRegionUtils";
import { initPeriodData, renderPeriodInfo } from "./renderPeriodUtils";
import "./infoSection.css";
import withRouter from "../../utils/routerUtils";

class InfoSection extends React.Component {
  constructor() {
    super();
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      from: "1700",
      // eslint-disable-next-line react/no-unused-state
      to: "1800",
    };
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props;
    if (type !== prevProps.type) {
      switch (type) {
        case "period":
          initPeriodData(this);
          break;
        default:
          break;
      }
    }
  }

  render() {
    const { type, data } = this.props;
    switch (type) {
      case "contributor":
      case "topic":
        return renderBasicInfo(type, data);
      case "period":
        return renderPeriodInfo(this);
      case "location":
        return renderRegionInfo(data);
      default:
        return "";
    }
  }
}

export default withRouter(InfoSection);
