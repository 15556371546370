/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import "./Login.css";
import { postWrapper } from "../../utils/axiosUtils";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailErr: "",
      passwordErr: "",
    };
  }

  inputChangeHandler(name, event) {
    const value = event.target.value.trim();
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      [`${name}Err`]: "",
    }));
  }

  submitHandler(event) {
    event.preventDefault();
    const { email, password } = this.state;
    const { successCallback } = this.props;
    if (!email || !password) return;
    postWrapper(
      "/user/signin",
      {
        email,
        password,
      },
      (data = {}) => {
        localStorage.setItem("profile", JSON.stringify(data));
        successCallback();
      },
      ({ data = {} }) => {
        switch (data.errorType) {
          case "email":
          case "password":
            this.setState((prevState) => ({
              ...prevState,
              [`${data.errorType}Err`]: data.message,
            }));
            break;
          default:
            break;
        }
      },
    );
  }

  render() {
    const { email, emailErr, password, passwordErr } = this.state;
    const { logoutCallback } = this.props;
    return (
      <>
        <div id="wrapper">
          <Header />
          <div
            style={{
              width: "400px",
              padding: "2rem",
              margin: "1rem auto",
              backgroundColor: "#2a2e49",
            }}
          >
            <h2>Login</h2>
            <form onSubmit={this.submitHandler.bind(this)} name="loginForm">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className={`form-control ${emailErr ? "is-invalid" : ""}`}
                  value={email}
                  onChange={this.inputChangeHandler.bind(this, "email")}
                />
                {emailErr && (
                  <span className="invalid-feedback">{emailErr}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className={`form-control ${passwordErr ? "is-invalid" : ""}`}
                  value={password}
                  onChange={this.inputChangeHandler.bind(this, "password")}
                />
                {passwordErr && (
                  <span className="invalid-feedback">{passwordErr}</span>
                )}
              </div>
              <div className="form-group" style={{ marginTop: "20px" }}>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Login"
                />
              </div>
            </form>
          </div>
          <Footer />
        </div>
        <Menu logoutCallback={logoutCallback} />
      </>
    );
  }
}

export default Home;
