/* eslint-disable */
import { postWrapper } from "../../utils/axiosUtils.js";
import notyfWrapper from "../../utils/notyfUtils";
let notyf = notyfWrapper.getInstance();
export async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "max-age=3600",
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

export function getSelectValues(selectObj, isAccess = false) {
  let items = selectObj.items;
  let result = [];
  items.forEach((item) => {
    let option = selectObj.options[item];
    result.push({
      label: option.label,
      value: option.value,
      status: option.status,
      ...(isAccess && { code: option.code }),
    });
  });
  return result;
}

export function clearSelectedValues(selectArray) {
  selectArray.forEach((select) => {
    select.clearOptions();
    select.clear(true);
  });
}

export function clearSelectedOptions(selectArray) {
  selectArray.forEach((select) => select.clearOptions());
}

export function updateCountryStateCityData(
  context,
  category,
  url = "",
  callback,
) {
  var headers = new Headers();
  headers.append(
    "X-CSCAPI-KEY",
    "c2NucHZTZUJqeXJhang4WXBZY3Q4RVQwWDRUdDV4enBrVWJVUHVKYw==",
  );
  var requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };
  fetch("https://api.countrystatecity.in/v1/countries" + url, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (callback && typeof callback == "function") {
        callback(result);
      }
    })
    .catch((error) => {
      notyf.error(`Error fetching ${category} data.`);
      console.log("error", error);
    });
}
export function getPlace(dataArray, value) {
  let shouldSkip = false;
  let label;
  dataArray.forEach((obj) => {
    if (shouldSkip) {
      return;
    }
    if (obj.value === value) {
      shouldSkip = true;
      label = obj.label;
    }
  });
  return label;
}

export function getTrendingData(requestObj, callback) {
  postWrapper("api/trends", requestObj, (data = []) => {
    callback(data, data.length);
  });
}

export function transformRegionsData(regions) {
  let result = [];
  if (!regions || !regions.length) {
    return result;
  }
  regions.forEach((t1) => {
    result.push({
      label: t1.label,
      value: t1.label,
    });
    t1["tier-2"].forEach((t2) => {
      result.push({
        label: t2.label,
        value: t2.label,
      });
      t2["tier-3"].forEach((t3) => {
        result.push({
          label: t3.label,
          value: t3.label,
        });
      });
    });
  });
  return result;
}

const getUniqueArray = (arr = []) => {
  return arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      arr.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
};

export function getConvertedLocationData(arr = [], query) {
  let result = [];
  arr.forEach((item) => {
    let label = item.location["country"].label,
      value = item.location["country"].value,
      regex = new RegExp(query, "i");

    if (regex.test(item.location["state"].label)) {
      label = item.location["state"].label;
      value = item.location["state"].value;
    } else if (regex.test(item.location["city"].label)) {
      label = item.location["city"].label;
      value = item.location["city"].value;
    }

    result.push({
      label,
      value,
      category: "location",
    });
  });
  return getUniqueArray(result);
}

export function getConvertedContributorData(arr = [], unique = false) {
  let result = [];
  arr.forEach((item) => {
    result.push({
      label: item.value,
      value: item.value,
      category: "contributor",
    });
  });
  if (unique) {
    return getUniqueArray(result);
  }
  return result;
}

export function getConvertedTopicData(arr = []) {
  let result = [];
  arr.forEach((topicArray) => {
    topicArray.topics.forEach((item) => {
      result.push({
        label: item.label,
        value: item.value,
        category: "topic",
      });
    });
  });
  return result;
}

export function generateContributorField() {
  const { collection } = this.state;
  const { collectionContributor } = collection;
  this.setState({
    collection: {
      ...collection,
      collectionContributor: [
        ...collectionContributor,
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
      ],
    },
  });
}

export function destroyCurrentContributorField(key) {
  const { collection } = this.state;
  let { collectionContributor } = collection;
  collectionContributor = collectionContributor.filter(
    (item) => item.key !== key,
  );
  this.setState({
    collection: {
      ...collection,
      collectionContributor: [...collectionContributor],
    },
  });
}

export function renderImagePreview(container, file, caption, credit, index) {
  let url = URL.createObjectURL(file);
  let divDocument = document.createElement("DIV");
  divDocument.setAttribute("class", "img-preview-container");
  let figure = document.createElement("FIGURE");
  figure.setAttribute("style", "width: inherit;");
  let image = document.createElement("IMG");
  image.setAttribute("class", "image-preview");
  image.setAttribute("style", "width: inherit; object-fit: contain;");
  image.setAttribute("src", url);
  image.addEventListener("load", (event) => {
    URL.revokeObjectURL(event.currentTarget.src);
  });
  figure.appendChild(image);
  if (caption) {
    let captionTag = document.createElement("FIGCAPTION");
    captionTag.appendChild(document.createTextNode(caption));
    figure.appendChild(captionTag);
  }
  divDocument.appendChild(figure);
  let creditLabel = document.createElement("LABEL");
  creditLabel.setAttribute("for", `credit-${index}`);
  creditLabel.textContent = "Photo Credit*";
  let creditField = document.createElement("INPUT");
  creditField.setAttribute("type", "text");
  creditField.setAttribute("id", `credit-${index}`);
  creditField.setAttribute("class", "image-credit");
  creditField.setAttribute("required", "required");
  if (credit === "") {
    divDocument.appendChild(creditLabel);
    divDocument.appendChild(creditField);
  } else if (credit) {
    creditField.value = credit;
    divDocument.appendChild(creditLabel);
    divDocument.appendChild(creditField);
  }
  container.appendChild(divDocument);
}

export function fileInputChangeListener(e) {
  let files = Array.from(e.target.files);
  const rowOfPhotos = document.getElementById("row-of-product-photos");
  if (files.length > 4) {
    notyf.error("You can only upload at most 4 files!");
    e.target.value = "";
    return;
  }
  const totalSize = (
    files.reduce(
      (previousValue, currentValue) => previousValue + currentValue.size,
      0,
    ) /
    (1024 * 1024)
  ).toFixed(2);
  if (totalSize > 10) {
    notyf.error("Files combined size should not be greater than 10 MB!");
    e.target.value = "";
    return;
  }
  document.getElementById("row-of-product-photos").textContent = "";
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    if (file) {
      renderImagePreview(
        rowOfPhotos,
        file,
        "Size: " + (file.size / (1024 * 1024)).toFixed(2) + " mb",
        "",
        i,
      );
    }
  }
}

export function renderInsertImagePreview() {
  let files = document.getElementById("collection-photos").files;
  let credits = getPhotoCredits();
  let container = document.getElementById("insert-image-preview");
  document.getElementById("insert-image-preview").textContent = "";
  for (let i = 0; i < files.length; i++) {
    renderImagePreview(container, files[i], "Credit: " + credits[i]);
  }
}

export function getPhotoCredits() {
  let filesCount = document.getElementById("collection-photos").files.length;
  let results = [];
  for (let i = 0; i < filesCount; i++) {
    let credit = document.getElementById(`credit-${i}`);
    if (credit) {
      if (credit.value.trim()) {
        results.push(credit.value.trim());
      } else {
        credit.focus();
        return [];
      }
    } else {
      return [];
    }
  }
  return results;
}

export function validateImageDetails() {
  let filesCount = document.getElementById("collection-photos").files.length;
  for (let i = 0; i < filesCount; i++) {
    let val = document.getElementById(`credit-${i}`).value.trim();
    if (!val) {
      notyf.error("Please enter credits for all the attached images.");
      document.getElementById(`credit-${i}`).focus();
      return false;
    }
  }
  return true;
}

export const formReset = (context) => {
  const {
    state: { collection },
  } = context;
  context.setState({
    collection: {
      ...collection,
      collectionTitle: "",
      collectionUrl: "",
      collectionFormat: [],
      accessKeys: [],
      collectionPermission: "",
      collectionDescription: "",
      collectionContributor: [
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
      ],
      timePeriod: { from: "1700", to: "1800" },
      topics: [],
      topicRecommendation: "",
      collectionPhotos: [],
      collectionRepository: "",
      repositoryInstitution: "",
      collectionRepositoryUrl: "",
      location: {
        city: "",
        state: "",
        country: "",
        address: "",
        zipcode: "",
        regions: {
          "tier-1": "",
          "tier-2": "",
          "tier-3": "",
        },
      },
      contact: {
        name: "",
        title: "",
        email: "",
        phone: "",
      },
      collectionComment: "",
      acknowledgement: true,
      imageAcknowledgement: true,
      // user: { firstName: "", lastName: "" },
      // submitDate: new Date().toLocaleDateString("en-CA"),
    },
    preventSubmit: false,
  });
};

export function customFormReset(context) {
  const {
    state: { collection },
  } = context;
  context.setState({
    collection: {
      ...collection,
      collectionTitle: "",
      collectionUrl: "",
      collectionFormat: [],
      accessKeys: [],
      collectionPermission: "",
      collectionDescription: "",
      collectionContributor: [
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
        {
          type: { label: "Select", value: "" },
          value: "",
          key: `contributor_${Math.random().toString(16).slice(2)}`,
        },
      ],
      timePeriod: { from: "1700", to: "1800" },
      topics: [],
      topicRecommendation: "",
      collectionPhotos: [],
      collectionComment: "",
      acknowledgement: true,
      imageAcknowledgement: true,
      // submitDate: new Date().toLocaleDateString("en-CA"),
    },
    preventSubmit: false,
  });
}

export function getRegions(location) {
  let regions = window.regionsData;
  let result = {
    "tier-1": {},
    "tier-2": {},
    "tier-3": {},
  };
  if (!regions || !regions.length) {
    return result;
  }
  if (location.country.label === "United States") {
    result["tier-1"] = regions[0].label;
    regions[0]["tier-2"].forEach((t2) => {
      t2["tier-3"].forEach((t3) => {
        if (t3.label === location.state.label) {
          result["tier-2"] = t2.label;
          result["tier-3"] = t3.label;
        }
      });
    });
  } else {
    result["tier-1"] = regions[1].label;
    regions[1]["tier-2"].forEach((t2) => {
      t2["tier-3"].forEach((t3) => {
        if (t3.label === location.country.label) {
          result["tier-2"] = t2.label;
          result["tier-3"] = t3.label;
        }
      });
    });
  }
  return result;
}

export const renderCategoryList = (data = [], displayAll = false) => {
  if (!data.length) {
    return "No data found!";
  } else if (data.length > 10 && !displayAll) {
    data = data.slice(0, 10);
  }
  return data.map((item, index) => {
    if (item.category === "period") {
      let periodData = item.value.split(" - ");
      return (
        <li key={`period-${index}`}>
          <a
            href={`caci-results?category=${item.category}&value=&from=${periodData[0]}&to=${periodData[1]}&sortBy=&orderBy=asc`}
          >
            {item.label || item.value}
          </a>
        </li>
      );
    } else {
      const params = new URLSearchParams();
      params.append("category", item.category);
      params.append("value", item.value);
      return (
        <li key={`${item.category}-${index}`}>
          <a
            href={`caci-results?${params.toString()}&from=&to=&sortBy=&orderBy=asc`}
          >
            {item.label || item.value}
          </a>
        </li>
      );
    }
  });
};

// export function getSubmitDate() {
//   let today = new Date();
//   const yyyy = today.getFullYear();
//   let mm = today.getMonth() + 1; // Months start at 0!
//   let dd = today.getDate();
//   if (dd < 10) dd = "0" + dd;
//   if (mm < 10) mm = "0" + mm;
//   today = yyyy + "-" + mm + "-" + dd;
//   return today;
// }

export function getContributorValues(items) {
  let result = [];
  result = items.filter((item) => item.value !== "");
  result = result.map((item) => {
    const { type, value } = item;
    return { type, value };
  });
  return result;
}

export function getFormData(context) {
  let { collection } = context.state;
  let { location, collectionContributor, collectionPhotos } = collection;
  location.regions = getRegions(location);
  collectionContributor = getContributorValues(collectionContributor);
  return {
    ...collection,
    location,
    // ...(context.formName === "insert-collection"
    //   ? { submitDate: getSubmitDate() }
    //   : {}),
    collectionContributor,
    ...(collectionPhotos.length
      ? { collectionPhotos }
      : { collectionPhotos: getPhotoCredits() }),
  };
}

export function validateForm(context) {
  const formInputs = getFormData(context);
  let keys = Object.keys(formInputs);
  for (let i = 0; i < keys.length; i++) {
    switch (keys[i]) {
      case "collectionTitle":
        {
          const val = formInputs[keys[i]];
          if (!val) {
            notyf.error(`Please enter a valid Collection Title.`);
            document.getElementById("collection-title").focus();
            return { error: true };
          }
        }
        break;
      case "collectionDescription":
        {
          const val = formInputs[keys[i]];
          if (!val) {
            notyf.error(`Please enter a valid Collection Description.`);
            document.getElementById("collection-description").focus();
            return { error: true };
          }
        }
        break;
      case "contact":
        {
          const val = formInputs[keys[i]];
          if (!val.name) {
            notyf.error(`Please enter a valid Contact Name.`);
            document.getElementById("contact-name").focus();
            return { error: true };
          }
          if (!val.email) {
            notyf.error(`Please enter a valid Contact Email.`);
            document.getElementById("contact-email").focus();
            return { error: true };
          } else if (val.email) {
            if (
              !String(val.email)
                .toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                )
            ) {
              notyf.error(`Please enter a valid Contact Email.`);
              document.getElementById("contact-email").focus();
              return { error: true };
            }
          }
        }
        break;
      case "collectionRepository":
        {
          const val = formInputs[keys[i]];
          if (!val) {
            notyf.error(`Please enter a valid Collection Repository.`);
            document.getElementById("collection-repository").focus();
            return { error: true };
          }
        }
        break;
      case "collectionFormat":
        {
          const val = formInputs[keys[i]];
          if (!val.length) {
            notyf.error(`Please select a valid Collection Format.`);
            document.querySelectorAll(".collection-format")[0].focus();
            return { error: true };
          }
        }
        break;
      case "accessKeys":
        {
          const val = formInputs[keys[i]];
          if (!val.length) {
            notyf.error(`Please select a valid Access Info.`);
            context.selectRefs.accessKeys.current.focus();
            return { error: true };
          }
        }
        break;
      case "topics":
        {
          const val = formInputs[keys[i]];
          if (!val.length) {
            notyf.error(`Please select a valid Topic.`);
            context.selectRefs.topics.current.focus();
            return { error: true };
          }
        }
        break;
      case "timePeriod":
        {
          const currentYear = new Date().getFullYear();
          const val = formInputs[keys[i]];
          if (isNaN(val.from) || val.from < 1600 || val.from > currentYear) {
            notyf.error(`Please select a valid Time Period.`);
            document.querySelector("#from").focus();
            return { error: true };
          } else if (!isNaN(val.to)) {
            if (val.to < 1600 || val.to > currentYear) {
              notyf.error(`Please select a valid Time Period.`);
              document.querySelector("#to").focus();
              return { error: true };
            } else if (val.to < val.from) {
              notyf.error(`Please select a valid Time Period.`);
              document.querySelector("#to").focus();
              return { error: true };
            }
          } else if (val.to.toLowerCase() !== "present") {
            notyf.error(`Please select a valid Time Period.`);
            document.querySelector("#to").focus();
            return { error: true };
          }
        }
        break;
      case "location":
        {
          const val = formInputs[keys[i]];
          if (!val.country) {
            notyf.error(`Please select a valid Country.`);
            context.selectRefs.country.current.focus();
            return { error: true };
          } else if (!val.state) {
            notyf.error(`Please select a valid State.`);
            context.selectRefs.state.current.focus();
            return { error: true };
          } else if (!val.city) {
            notyf.error(`Please select a valid City.`);
            context.selectRefs.city.current.focus();
            return { error: true };
          } else if (!val.address) {
            notyf.error(`Please enter a valid Address.`);
            document.getElementById("address").focus();
            return { error: true };
          } else if (!val.zipcode) {
            notyf.error(`Please enter a valid Zipcode.`);
            document.getElementById("zipcode").focus();
            return { error: true };
          }
        }
        break;
      // case "user":
      //   {
      //     const val = formInputs[keys[i]];
      //     if (!val.firstName) {
      //       notyf.error(`Please enter a valid First Name.`);
      //       document.getElementById("user-first-name").focus();
      //       return { error: true };
      //     } else if (!val.lastName) {
      //       notyf.error(`Please enter a valid Last Name.`);
      //       document.getElementById("user-last-name").focus();
      //       return { error: true };
      //     }
      //   }
      //   break;
      default:
        break;
    }
  }
  return {
    error: false,
    data: formInputs,
  };
}
