export const fillColor = (context) => {
  const { sliderOne, sliderTwo, sliderMinValue, sliderMaxValue, sliderTrack } =
    context;
  const percent1 =
    ((sliderOne.value - sliderMinValue) / (sliderMaxValue - sliderMinValue)) *
    100;
  const percent2 =
    ((sliderTwo.value - sliderMinValue) / (sliderMaxValue - sliderMinValue)) *
    100;
  sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #9bf1ff ${percent1}% , #9bf1ff ${percent2}%, #dadae5 ${percent2}%)`;
};

export const slideOne = (context) => {
  const { minGap, sliderOne, sliderTwo } = context;
  let {
    state: { from },
  } = context;
  if (parseInt(sliderTwo.value, 10) - parseInt(sliderOne.value, 10) <= minGap) {
    from = parseInt(sliderTwo.value, 10) - minGap;
  } else {
    from = parseInt(sliderOne.value, 10);
  }
  context.setState({
    from,
  });
  fillColor(context);
};

export const slideTwo = (context) => {
  const { minGap, sliderOne, sliderTwo } = context;
  let {
    state: { to },
  } = context;
  if (parseInt(sliderTwo.value, 10) - parseInt(sliderOne.value, 10) <= minGap) {
    to = parseInt(sliderOne.value, 10) + minGap;
  } else {
    to = parseInt(sliderTwo.value, 10);
  }
  context.setState({
    to,
  });
  fillColor(context);
};

export const resetSlider = (context) => {
  context.setState({
    from: 1700,
    to: 1800,
  });
  slideOne(context);
  slideTwo(context);
};
