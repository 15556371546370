import { Notyf } from "notyf";

export default (function notyfWrapper() {
  let notyf;

  function createInstance() {
    const object = new Notyf({
      duration: 10000,
      ripple: false,
      position: {
        x: "right",
        y: "top",
      },
      dismissible: true,
    });
    return object;
  }

  return {
    getInstance() {
      if (!notyf) {
        notyf = createInstance();
      }
      return notyf;
    },
  };
})();
