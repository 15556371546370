/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../../utils/routerUtils";

const closeMenu = (event) => {
  event.preventDefault();
  event.stopPropagation();
  document.body.classList.remove("is-menu-visible");
};

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem("profile"));
  }

  logoutHandler(event) {
    event.preventDefault();
    const {
      router: { navigate },
      logoutCallback,
    } = this.props;
    localStorage.removeItem("profile");
    logoutCallback();
    navigate("/");
  }

  render() {
    const { user } = this;
    return (
      <nav id="menu" onClick={closeMenu}>
        <div className="inner">
          <ul className="actions stacked">
            <li>
              <Link
                to={user ? "/caci/admin-list" : "/"}
                className="button primary fit"
              >
                Home
              </Link>
            </li>
            <li>
              <Link to="/caci/insert-collection" className="button fit">
                Add Collection
              </Link>
            </li>
            <li>
              {user ? (
                <span
                  role="button"
                  tabIndex={0}
                  className="button fit"
                  onClick={this.logoutHandler.bind(this)}
                >
                  Log Out
                </span>
              ) : (
                <Link to="/login" className="button fit">
                  Log In
                </Link>
              )}
            </li>
          </ul>
        </div>
        <span role="button" tabIndex={0} className="close" onClick={closeMenu}>
          Close
        </span>
      </nav>
    );
  }
}

export default withRouter(Menu);
