import React from "react";
// eslint-disable-next-line no-unused-vars
import $ from "jquery";
import AsyncSelect from "react-select/async";
import withRouter from "../../utils/routerUtils";
import "./Home.css";
import { postWrapper, postAsync } from "../../utils/axiosUtils";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import InfoSection from "../InfoSection/InfoSection";
import {
  getTrendingData,
  getConvertedLocationData,
  getConvertedTopicData,
  getConvertedContributorData,
} from "../../assets/js/utils";
import { renderArticles, renderRadioButton } from "./renderUtils";
import initialState from "./initialState";

const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    getTrendingData(
      {
        category: "",
        limit: 5,
      },
      (data) => {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          trending: data,
        });
      },
    );
    this.caciTopNav("contributor");
    this.debouncedLoadOptions = debounce(this.loadOptions, 500);
  }

  caciTopNav = async (selectedCategory) => {
    let {
      infoSectionData: {
        [selectedCategory]: { directory = [] },
      },
    } = this.state;
    if (!directory.length) {
      if (selectedCategory === "topic") {
        directory = await postAsync("api/topics");
        directory.forEach((item, index) => {
          directory[index].category = "topic";
        });
      } else if (selectedCategory === "contributor") {
        directory = getConvertedContributorData(
          await postAsync("api/contributors"),
        );
      } else if (selectedCategory === "location") {
        directory = await postAsync("api/tiers");
      }
    }
    getTrendingData(
      {
        category: selectedCategory,
        limit: 10,
      },
      (data) => {
        this.setState((prevState) => ({
          selectedCategory,
          infoSectionData: {
            ...prevState.infoSectionData,
            [selectedCategory]: {
              directory,
              trending: data,
            },
          },
        }));
      },
    );
  };

  loadOptions = (query, callback) => {
    const { selectedCategory: category } = this.state;
    postWrapper(
      "api/search",
      {
        query: query.trim(),
        category: `${category}s`,
      },
      (response = []) => {
        if (category === "location") {
          callback([...getConvertedLocationData(response, query.trim())]);
        } else if (category === "contributor") {
          const data = getConvertedContributorData(response, true);
          callback(data);
        } else if (response.length > 0) {
          callback(getConvertedTopicData(response) || []);
        } else {
          callback([]);
        }
      },
      () => {
        callback([]);
      },
    );
  };

  handleSelectChange = (selectedOption) => {
    const {
      router: { navigate },
    } = this.props;
    this.setState(
      (prevState) => ({
        select: {
          ...prevState.select,
          selectedOption,
        },
      }),
      () => {
        const { value, category } = selectedOption;
        navigate(
          `caci-results?category=${category}&value=${value}&from=&to=&sortBy=&orderBy=asc`,
        );
      },
    );
  };

  render() {
    const {
      selectedCategory,
      search: { selectedOption },
      infoSectionData,
    } = this.state;
    const { logoutCallback } = this.props;
    return (
      <>
        <div id="wrapper">
          <Header />
          <Banner />
          <div id="main">
            <section id="one" className="tiles">
              {renderArticles(this)}
            </section>
            <section id="two">
              <div className="inner">
                <header id="searchtop" className="major">
                  <h2>What are you looking for?</h2>
                </header>
                <div className="row">
                  <div className="col-12 col-12-small">
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px",
                      }}
                    >
                      {renderRadioButton(
                        this,
                        "contributor",
                        "Author",
                        this.caciTopNav,
                      )}
                      {renderRadioButton(
                        this,
                        "topic",
                        "Topic",
                        this.caciTopNav,
                      )}
                      {renderRadioButton(
                        this,
                        "period",
                        "Time Period",
                        this.caciTopNav,
                      )}
                      {renderRadioButton(
                        this,
                        "location",
                        "Region",
                        this.caciTopNav,
                      )}
                    </div>
                  </div>
                </div>
                {selectedCategory !== "period" && (
                  <div className="col-12 col-12-small">
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <AsyncSelect
                        placeholder="Search"
                        value={selectedOption}
                        loadOptions={this.debouncedLoadOptions}
                        classNamePrefix="react-select"
                        onChange={this.handleSelectChange}
                        loadingMessage={() => "Loading..."}
                      />
                    </div>
                  </div>
                )}
                <InfoSection
                  type={selectedCategory}
                  data={infoSectionData[selectedCategory]}
                />
              </div>
            </section>
          </div>
          <Footer />
        </div>
        <Menu logoutCallback={logoutCallback} />
      </>
    );
  }
}

export default withRouter(Home);
