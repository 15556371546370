/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { postWrapper } from "../../utils/axiosUtils";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import "./Collection.css";
import pic7 from "../../images/pic07.jpg";

const getRequestObj = () => {
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id") || "";
  return {
    _id: id,
  };
};

class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.slidePosition = 1;
    this.state = {
      collection: {},
      queryObj: getRequestObj(),
      fetching: true,
    };
  }

  componentDidMount() {
    const { queryObj } = this.state;
    this.slides = document.getElementsByClassName("img-container");
    postWrapper("api/collection", queryObj, (collectionResult = {}) => {
      this.setState({ collection: collectionResult, fetching: false }, () => {
        if (this.slides.length > 0) {
          this.slideShow(this.slidePosition);
        }
      });
      postWrapper("api/updateTrends", {
        category: "period",
        value: `${collectionResult.timePeriod.from} - ${collectionResult.timePeriod.to}`,
      });
    });
  }

  prevSlide = () => {
    this.slideShow((this.slidePosition += -1));
  };

  nextSlide = () => {
    this.slideShow((this.slidePosition += 1));
  };

  slideShow = (n) => {
    if (n > this.slides.length) {
      this.slidePosition = 1;
    }
    if (n < 1) {
      this.slidePosition = this.slides.length;
    }
    for (let i = 0; i < this.slides.length; i += 1) {
      this.slides[i].style.display = "none";
    }
    this.slides[this.slidePosition - 1].style.display = "block";
  };

  render() {
    const { collection, fetching } = this.state;
    const { logoutCallback } = this.props;
    if (
      collection &&
      Object.keys(collection).length === 0 &&
      Object.getPrototypeOf(collection) === Object.prototype
    ) {
      return "";
    }
    return (
      <>
        <div id="wrapper">
          <Header />
          <section id="banner" className="style2">
            <div className="inner">
              <span className="image">
                <img src={pic7} alt="" />
              </span>
              <header className="major">
                <h1>Current Archives and Collections Index</h1>
              </header>
              <div className="content">
                <p>Your quick guide to collections on your favorite topics</p>
              </div>
            </div>
          </section>
          <div id="main">
            <section id="two">
              {fetching ? (
                "Please wait while we fetch the collection details..."
              ) : (
                <div className="inner">
                  <div className="row">
                    <div className="col-4 col-6-small">
                      <div className="slideshow-container">
                        {collection.collectionPhotos.length ? (
                          <>
                            {collection.collectionPhotos.map((photo, index) => (
                              <div
                                className="img-container"
                                key={`${photo.name}-${index}`}
                              >
                                <div className="img-message-info">
                                  {`${index + 1} / ${
                                    collection.collectionPhotos.length
                                  }`}
                                </div>
                                <img src={photo.url} alt={photo.name} />
                                <div className="img-credit">
                                  {`Credit: ${photo.credit}`}
                                </div>
                              </div>
                            ))}
                            <a
                              role="button"
                              tabIndex={0}
                              className="img-back"
                              onClick={this.prevSlide}
                            >
                              &#10094;
                            </a>
                            <a
                              role="button"
                              tabIndex={0}
                              className="img-forward"
                              onClick={this.nextSlide}
                            >
                              &#10095;
                            </a>
                          </>
                        ) : (
                          "No Images Have been Uploaded."
                        )}
                      </div>
                    </div>
                    <div className="col-8 col-6-small">
                      <div>
                        <h3
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          {collection.collectionTitle || "NA"}
                        </h3>
                        <div>
                          <span>
                            <b>Collection Format: </b>
                          </span>
                          <span title="collection format">
                            {collection.collectionFormat.length
                              ? collection.collectionFormat.join(", ")
                              : "NA"}
                          </span>
                        </div>
                        <div>
                          <span>
                            <b>Access Keys: </b>
                          </span>
                          {collection.accessKeys.map((key) => (
                            <span
                              className="select-item"
                              key={key.code}
                              title={key.label}
                            >
                              {key.code}
                            </span>
                          ))}
                        </div>
                        <div>
                          <span>
                            <b>Topics/Keywords: </b>
                          </span>
                          {collection.topics.map((topic) => (
                            <span
                              className="select-item"
                              key={topic.label}
                              title={topic.label}
                            >
                              {topic.label}
                            </span>
                          ))}
                        </div>
                        <div>
                          <p title="collection description">
                            <span>
                              <b>Description: </b>
                              {collection.collectionDescription}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          border: "1px solid",
                        }}
                      >
                        <h3>Additional Information:</h3>
                        <ul>
                          <li>
                            <b>Collection URL: </b>
                            {collection.collectionUrl ? (
                              <a
                                href={collection.collectionUrl}
                                target="_blank"
                              >
                                {collection.collectionUrl}
                              </a>
                            ) : (
                              "NA"
                            )}
                          </li>
                          <li>
                            <b>Collection Contributor: </b>
                            {collection.collectionContributor.map(
                              (contributor, index) => (
                                <span
                                  className="select-item"
                                  title={`${contributor.type.label} - ${contributor.value}`}
                                  key={`${contributor.value} - ${index}`}
                                >
                                  {contributor.value}
                                </span>
                              ),
                            )}
                          </li>
                          {collection.repositoryInstitution ? (
                            <li>
                              <b>Institution: </b>
                              <span>{collection.repositoryInstitution}</span>
                            </li>
                          ) : (
                            <li>
                              <b>Repository: </b>
                              <span>{collection.collectionRepository}</span>
                            </li>
                          )}
                          <li>
                            <b>Repository URL: </b>
                            {collection.collectionRepositoryUrl ? (
                              <a
                                href={collection.collectionRepositoryUrl}
                                target="_blank"
                              >
                                {collection.collectionRepositoryUrl}
                              </a>
                            ) : (
                              "NA"
                            )}
                          </li>
                          <li>
                            <b>Time Period: </b>
                            <span>
                              {`${collection.timePeriod.from} - ${collection.timePeriod.to}`}
                            </span>
                          </li>
                          <li>
                            <b>Location: </b>
                            <span id="location">
                              {`${collection.location.city.label}, ${collection.location.state.label}, ${collection.location.country.label}`}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-12-small">
                      <h5>Contact:</h5>
                      <ul>
                        {collection.contact.name && (
                          <li>{collection.contact.name}</li>
                        )}
                        {collection.contact.title && (
                          <li>{collection.contact.title}</li>
                        )}
                        {collection.contact.email && (
                          <li>{collection.contact.email}</li>
                        )}
                        {collection.contact.phone && (
                          <li>{collection.contact.phone}</li>
                        )}
                      </ul>
                    </div>
                    {/* <div className="col-6 col-12-small">
                      <div
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <h5>Community Feedback</h5>
                        <ol>
                          <li>Quote</li>
                          <li>Quote</li>
                          <li>Quote</li>
                          <li>Quote</li>
                        </ol>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
            </section>
          </div>
          <Footer />
        </div>
        <Menu logoutCallback={logoutCallback} />
      </>
    );
  }
}

export default Collection;
