import {
  getPhotoCredits,
  renderImagePreview,
  validateImageDetails,
  renderInsertImagePreview,
} from "../../assets/js/utils";

export const showModal = (context) => {
  context.prevFiles = document.getElementById("collection-photos").files || [];
  context.prevCredits = getPhotoCredits() || [];
  context.modal.style.display = "block";
  document.body.style.overflow = "hidden";
};

export const closeModal = (context) => {
  document.getElementById("row-of-product-photos").textContent = "";
  if (context.prevFiles.length) {
    const rowOfPhotos = document.getElementById("row-of-product-photos");
    for (let i = 0; i < context.prevFiles.length; i += 1) {
      const file = context.prevFiles[i];
      if (file) {
        renderImagePreview(
          rowOfPhotos,
          file,
          `Size: ${(file.size / (1024 * 1024)).toFixed(2)} mb`,
          context.prevCredits[i],
          i,
        );
      }
    }
  } else {
    document.getElementById("collection-photos").value = "";
  }
  document.body.style.overflow = "";
  context.modal.style.display = "none";
};

export const validateModal = (context) => {
  if (validateImageDetails()) {
    // if (!document.getElementById("image-acknowledgement").checked) {
    //   context.notyf.error(
    //     "Please give CACI permission to use any or all of the images provided on context form."
    //   );
    //   return;
    // }
    renderInsertImagePreview();
    document.body.style.overflow = "";
    context.modal.style.display = "none";
  }
};
