import React from "react";
import Comment from "../Comment/Comment";
import { renderCategoryList } from "../../assets/js/utils";
import { slideOne, slideTwo, resetSlider } from "./sliderUtils";

export const initPeriodData = (context) => {
  context.sliderOne = document.getElementById("slider-1");
  context.sliderTwo = document.getElementById("slider-2");
  context.minGap = 0;
  context.sliderTrack = document.querySelector(".slider-track");
  context.sliderMinValue = 1600;
  context.sliderMaxValue = new Date().getFullYear();
  slideOne(context);
  slideTwo(context);
};

export const renderPeriodInfo = (context) => {
  const currentYear = new Date().getFullYear();
  const {
    router: { navigate },
  } = context.props;
  const {
    data: { trending },
  } = context.props;

  const clickHandler = () => {
    const { from, to } = context.state;
    resetSlider(context);
    navigate(
      `caci-results?category=period&value=&from=${from}&to=${to}&sortBy=&orderBy=asc`,
    );
  };

  const { from, to } = context.state;
  return (
    <div className="row" id="caci-period-div">
      <div className="col-12 slider-wrapper">
        <div className="slider-values">
          <label htmlFor="range1">
            TIME PERIOD:
            <span id="range1">
              {from} - {to}
            </span>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="slider-container" style={{ flexBasis: "85%" }}>
            <div className="slider-track" />
            <input
              type="range"
              id="slider-1"
              onInput={slideOne.bind(context, context)}
              value={from}
              min="1600"
              max={currentYear}
            />
            <input
              type="range"
              id="slider-2"
              onInput={slideTwo.bind(context, context)}
              value={to}
              min="1600"
              max={currentYear}
            />
          </div>
          <input type="button" value="Search" onClick={clickHandler} />
        </div>
      </div>
      <div className="row col-12" style={{ width: "105%" }}>
        <div className="col-6 col-12-small">
          <div
            style={{ border: "1px solid", padding: "5px", marginTop: "5px" }}
          >
            <h3>Trending Time Periods</h3>
            <ol id="trending-periods">{renderCategoryList(trending)}</ol>
          </div>
        </div>
        <div className="col-6 col-12-small">
          <div
            style={{ border: "1px solid", padding: "5px", marginTop: "5px" }}
          >
            <Comment
              type="period"
              heading="Don't see the time period you're looking for? Tell us about it!"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
